<template>
  <default-layout>
    <div class="container listing--deals">
      <div class="title--text">
        {{ $t('homepage.title.flashSale') }}
      </div>
      <client-only>
        <div class="listing--deals-wrapper" v-if="listings && listings.length > 0">
          <div v-for="listing in listings" :key="listing.uuid" class="listing--item">
            <card-listing :listing="listing" :allowMultiImage="true"></card-listing>
          </div>
        </div>
      </client-only>
      <pagination
        v-if="metaListing"
        :push-state="false"
        :meta="metaListing"
        @changePage="getDiscountListings"
        :maxVisibleButtons="5"
      />
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const CardListing = () => import('@/components/utils/card-listing');
const Pagination = () => import('@/components/utils/fractal-pagination.vue');
import { mapState } from 'vuex';

import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    CardListing,
    Pagination,
  },
  computed: {
    ...mapState({
      metaListing: (state) => state.v2.search.meta,
      listings: (state) => state.v2.search.listings,
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('breadcrumb.listingDeals'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {
    async getDiscountListings(page) {
      this.$store.commit('global/SET_LOADING', true);

      this.$store.commit('v2/search/set_listings', { data: [], meta: null });
      this.$store.commit('v2/search/set_premier_listings', []);
      this.$store.commit('v2/search/set_projects', { data: [], meta: null });
      this.$store.commit('v2/search/SET_OFFICIAL_PARTNER_AREAS_DATA', []);
      this.$store.commit('v2/search/SET_OFFICIAL_PARTNER_AREAS_META', null);

      this.$store.commit('v2/search/SET_LISTING_TYPE', 'R');
      this.$store.commit('v2/search/SET_PAGE', page ? page : 1);

      await this.$store.dispatch('v2/search/getDiscountListing');
      this.$store.commit('global/SET_LOADING', false);

      console.log('ISI LISTINGS: ', this.listings);
    },
  },
  mounted() {
    this.getDiscountListings();
  },
};
</script>
